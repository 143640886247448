<template>
  <div>
    <!-- Phone number -->
    <b-form-group
      label="Phone Number"
      label-for="login-email"
    >
      <phone-number-input
        ref="phoneNumberInput"
        :phone-number.sync="phoneNumberText"
        :area-code.sync="areaCodeText"
      />
    </b-form-group>
    <!-- Verification Code -->
    <b-form-group
      label="Verification Code"
      label-for="verification-code"
    >
      <validation-provider
        #default="{ errors }"
        name="verification code"
        rules="required"
      >
        <b-form-input
          id="verification-code"
          :value="verificationCode"
          name="verification-code"
          placeholder="Verification Code"
          autocomplete="off"
          @input="(val) => $emit('update:verificationCode', val)"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import { extend, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import PhoneNumberInput from './PhoneNumberInput.vue'

export default {
  name: 'TelephoneNumber',
  components: {
    BFormGroup,
    BFormInput,
    PhoneNumberInput,
    ValidationProvider,
  },
  props: {
    areaCode: {
      type: String,
      default: '',
    },
    phoneNumber: {
      type: String,
      default: '',
    },
    verificationCode: {
      type: String,
      default: '',
    },
  },
  data() {
    extend('required', {
      ...required,
      message: 'Please enter your {_field_}.',
    })
    return {
      phoneNumberText: '',
      areaCodeText: '',
    }
  },
  watch: {
    phoneNumberText(value) {
      this.$emit('update:phoneNumber', value)
    },
    areaCodeText(value) {
      this.$emit('update:areaCode', value)
    },
  },
  mounted() {
    this.areaCodeText = this.areaCode
  },
}
</script>
<style lang="scss">
</style>
