<template>
  <div class="validate-span-box">
    <b-input-group-prepend>
      <v-select
        :value="areaCode"
        :options="areaCodes"
        label="text"
        class="b-v-select"
        :reduce="(val) => val.text"
        @input="(val) => $emit('update:areaCode', val)"
      >
        <template #option="{ image, text }">
          <div class="b-dropdown-country">
            <img
              :src="image"
              alt=""
            >
            <span> {{ text }}</span>
          </div>
        </template>
      </v-select>
    </b-input-group-prepend>
    <validation-provider
      #default="{ errors }"
      name="phone number"
      rules="required|isEleven"
      class="validation-phonenumber"
    >
      <div style="display: flex;">
        <!-- TODO 文本改为number  -->
        <b-form-input
          id="phoneNumber"
          :value="phoneNumber"
          type="text"
          :state="errors.length > 0 ? false:null"
          name="phone number"
          placeholder="Phone number"
          :max-length="areaCode.text==='+55'?12:11"
          autocomplete="off"
          @input="(val) => $emit('update:phoneNumber', val)"
        />
        <b-input-group-append
          v-if="source !== 'login' "
          class="b-append-btn"
        >
          <b-button
            variant="outline-primary"
            :disabled="!isSend ? true : isSendSMS"
            @click="sendSMS"
          >
            {{ sendSMSText }}
          </b-button>
        </b-input-group-append>
      </div>

      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </div>
</template>
<script>
import {
  defineComponent, ref, getCurrentInstance, computed,
} from '@vue/composition-api'
import {
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { showToast, checkIsPhoneValid } from '@/libs/utils'

const _ = require('lodash')
const portugalFlags = require('@/assets/images/merchant/portugalFlags.png')
const chinaFlags = require('@/assets/images/merchant/chinaFlags.png')

export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    const areaCodes = ref([
      { image: portugalFlags, text: '+55' },
      { image: chinaFlags, text: '+86' },
    ])
    const platform = ref(2) // 1:用户端 2:商户端
    const isSendSMS = ref(false)
    const sendSMSText = ref('Send')

    const sendSMS = async () => {
      if (_.trim(proxy.phoneNumber) !== '') {
        proxy.$showLoading()
        const params = {
          scene: Number(proxy.scene), // 注册 3
          platform: platform.value,
          phone: proxy.phoneNumber,
          area_code: proxy.areaCode.substring(1),
        }
        const res = await proxy.$api.sendSMS(params).catch(error => {
          proxy.$hideLoading()
          showToast({ proxy, title: 'danger', message: error.message })
        })
        proxy.$hideLoading()
        const { code, message } = res.data
        if (code === 200) {
          // 设置按钮倒计时
          isSendSMS.value = true
          sendSMSText.value = 60
          const timer = setInterval(() => {
            sendSMSText.value -= 1
            if (sendSMSText.value === 0) {
              isSendSMS.value = false
              sendSMSText.value = 'Send'
              clearInterval(timer)
            }
          }, 1000)
        } else {
          showToast({ proxy, title: 'danger', message })
        }
        return true
      }
      showToast({ proxy, title: 'danger', message: 'Please enter your phone number.' })
      return false
    }
    const isSend = computed(() => checkIsPhoneValid(proxy.phoneNumber, proxy.areaCode.substring(1)))
    // 手机号验证：小于11位且不等于0
    extend('required', {
      ...required,
      message: 'Please enter your {_field_}.',
    })
    extend('isEleven', {
      validate(value) {
        return checkIsPhoneValid(value, proxy.areaCode.substring(1))
        // return value.length >= 11
      },
      message: 'The format of phone number is incorrect.',
    })
    return {
      areaCodes, platform, isSendSMS, sendSMSText, sendSMS, isSend,
    }
  },
  components: {
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInput,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    source: {
      type: String,
      default: '',
    },
    areaCode: {
      type: String,
      default: '',
    },
    phoneNumber: {
      type: String,
      default: '',
    },
  },
  inject: ['scene'],
})
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.validate-span-box {
  display: flex;
  width: 100%;
  flex-wrap: nowrap !important;
  .vs__clear {
    display: none;
  }
  .b-v-select {
    width: 95px;
    height: 38px;
    .vs__dropdown-toggle {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      height: 38px;
    }
    img {
      width: 30px;
      height: auto;
    }
    & > button {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .b-dropdown-country {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
  }
  .validation-phonenumber {
    width: 100%;
    display: inline-block;
  }
}
input[name="phone number"] {
  border-radius: 0;
}
.b-append-btn {
  & > button {
    width: 80px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
